/* external */
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

/* material-ui */
import { Box, Tab, useMediaQuery } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

/* internal */
import {
  DealerPicker,
  Loading,
  LoadingBackdrop,
  useDealerContext,
} from 'components/MaterialUI';
import { useSnackMutation } from 'utils/useSnackMutation';
import { useSnackbar } from 'notistack';
import { parseErrors } from 'modules/npv_inventory/utils';

import { PAYMENT_TYPES } from '../../constants';

import EditPhotosMedia from './EditPhotosMedia';
import EditPricing from './EditPricing';
import EditSpecsFeatures from './EditSpecsFeatures';
import EditUnitInfo from './EditUnitInfo';
import SaveChanges from './SaveChanges';
import UnitDetails from './UnitDetails';
import UnitHistory from './UnitHistory';
import {
  UPDATE_DETAILED_PRICING,
  CREATE_DETAILED_PRICING,
  UPLOAD_PHOTOS,
  UPLOAD_VIDEOS,
} from '../common/Queries';

const NPV_VEHICLE_QUERY = gql`
  query npvVehicle($id: Int!) {
    npv {
      getVehicle(id: $id) {
        id
        ...NpvVehicleDetails
        ...NpvVehicleInfo
        ...NpvVehiclePricing
        ...NpvVehicleSpecsFeatures
        ...NpvVehiclePhotos
        ...NpvVehicleVideos
      }
    }
  }
  ${UnitDetails.fragments.vehicle}
  ${EditUnitInfo.fragments.vehicle}
  ${EditPricing.fragments.vehicle}
  ${EditSpecsFeatures.fragments.vehicle}
  ${EditPhotosMedia.fragments.vehicle}
`;

const UPDATE_NPV_VEHICLE = gql`
  mutation updateNpvVehicle($id: Int!, $data: NpvVehicleInput!) {
    npv {
      updateVehicle(id: $id, data: $data) {
        id
        ...NpvVehicleDetails
        ...NpvVehicleInfo
        ...NpvVehiclePricing
        ...NpvVehicleSpecsFeatures
        ...NpvVehiclePhotos
        ...NpvVehicleVideos
      }
    }
  }
  ${UnitDetails.fragments.vehicle}
  ${EditUnitInfo.fragments.vehicle}
  ${EditPricing.fragments.vehicle}
  ${EditSpecsFeatures.fragments.vehicle}
  ${EditPhotosMedia.fragments.vehicle}
`;

const EditUnit = ({ vehicleId }) => {
  const { dealerId } = useDealerContext();
  const [tabIndex, setTabIndex] = useState('0');

  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const tabList = [
    { label: 'Unit Info', value: '0' },
    { label: 'Pricing', value: '1' },
    { label: 'Photos & Media', value: '2' },
    { label: 'Specifications & Features', value: '3' },
    { label: 'Unit History', value: '4' },
  ];

  function setDefaultValues(vehicleData) {
    for (let [k, v] of Object.entries(vehicleData)) {
      if (
        !/^(?:spec[^a-z]|feat[^a-z])/.test(k) ||
        v === undefined ||
        v === null
      ) {
        continue;
      }

      setValue(k, v);
    }

    setValue('cost', vehicleData?.cost ?? 0);
    setValue('defaultDownPayment', vehicleData?.defaultDownPayment ?? 0);
    setValue('description', vehicleData?.description ?? '');
    setValue('exteriorColourName', vehicleData?.exteriorColourName);
    setValue('interiorColourName', vehicleData?.interiorColourName);
    setValue('modelId', vehicleData?.modelId);
    setValue('msrp', vehicleData?.msrp ?? 0);
    setValue('npvMakeId', vehicleData?.model?.make?.id ?? '');
    setValue(
      'npvManufacturerId',
      vehicleData?.model?.make?.manufacturer?.id ?? '',
    );
    setValue('npvTypeId', vehicleData?.model?.npvType?.id ?? '');
    setValue('odometer', vehicleData?.odometer ?? null);
    setValue('odometerUnits', vehicleData?.odometerUnits ?? 'NA');
    setValue('regularPrice', vehicleData?.regularPrice ?? 0);
    setValue(
      'selectedYear',
      vehicleData?.model?.make?.year ?? new Date().getFullYear(),
    );
    setValue('stockNumber', vehicleData?.stockNumber);
    setValue('stockStatusId', vehicleData?.stockStatusId); // TODO this can be hard coded to (in stock)
    setValue('stockType', vehicleData?.stockType);
    setValue('vin', vehicleData?.vin);
    setValue('year', vehicleData?.year);
    setValue('tag1Id', vehicleData?.tag1Id ?? 0);
    setValue('tag2Id', vehicleData?.tag2Id ?? 0);

    setPhotoList(vehicleData?.photos ?? []);
    setVideoList(vehicleData?.videos ?? []);
    setLabelId(
      vehicleData?.photos.find(photo => photo.isFloorPlan)?.labelId ?? null,
    );

    // Set detailed pricing
    const detailedPricingData = vehicleData?.detailedPricing ?? [];
    const formDetailedPricing = detailedPricingData.map(pricing => ({
      ...pricing,
      noExpiry: !pricing.expiry,
      paymentType: pricing.allowCash
        ? pricing.allowFinance
          ? PAYMENT_TYPES.BOTH
          : PAYMENT_TYPES.CASH
        : PAYMENT_TYPES.FINANCE,
      showOnWebsite: pricing.showOnWebsite ? 'true' : 'false',
    }));
    fieldArrayMethods.replace(formDetailedPricing);
  }

  // Query
  const { loading, data: vehicleData } = useQuery(NPV_VEHICLE_QUERY, {
    variables: { id: vehicleId },
    onCompleted: data => {
      setDefaultValues(data?.npv?.getVehicle);
    },
  });

  const vehicle = vehicleData?.npv?.getVehicle;
  const methods = useForm({
    defaultValues: {
      dealerId,
      description: vehicle?.description ?? '',
      modelId: vehicle?.modelId,
      odometer: vehicle?.odometer ?? null,
      odometerUnits: vehicle?.odometerUnits ?? 'NA',
      stockNumber: vehicle?.stockNumber,
      stockStatusId: vehicle?.stockStatusId,
      stockType: vehicle?.stockType,
      vin: vehicle?.vin,
      cost: vehicle?.cost ?? 0,
      msrp: vehicle?.msrp ?? 0,
      regularPrice: vehicle?.regularPrice ?? 0,
      defaultDownPayment: vehicle?.defaultDownPayment ?? 0,
      tag1Id: vehicle?.tag1Id ?? 0,
      tag2Id: vehicle?.tag2Id ?? 0,
    },
  });
  const { control, formState, setValue } = methods;

  const fieldArrayMethods = useFieldArray({
    control,
    name: 'detailedPricing',
    keyName: 'key',
  });
  const photosFieldArray = useFieldArray({
    control,
    name: 'photos',
    keyName: 'key',
  });
  const videosFieldArray = useFieldArray({
    control,
    name: 'videos',
    keyName: 'key',
  });

  const [photoList, setPhotoList] = useState(photosFieldArray.fields);
  const [videoList, setVideoList] = useState(videosFieldArray.fields);
  const [labelId, setLabelId] = useState(
    photoList.find(photo => photo.isFloorPlan)?.labelId ?? null,
  );

  // Mutations
  const [updateNpvVehicle, { loading: updateLoading }] = useSnackMutation(
    UPDATE_NPV_VEHICLE,
    'Vehicle updated successfully!',
    e => `Error updating vehicle: ${parseErrors(e)}`,
    data => setDefaultValues(data?.npv?.updateVehicle),
  );

  const [updateDetailedPricing, { loading: updateDetailedPricingLoading }] =
    useSnackMutation(
      UPDATE_DETAILED_PRICING,
      'Successfully updated detailed pricing.',
      'Unable to update detailed pricing, please check your input values.',
    );

  const [createDetailedPricing, { loading: createDetailedPricingLoading }] =
    useSnackMutation(
      CREATE_DETAILED_PRICING,
      'Successfully created detailed pricing.',
      'Unable to create detailed pricing, please check your input values.',
    );

  const [uploadPhotosMutation, { loading: uploadPhotosLoading }] =
    useSnackMutation(
      UPLOAD_PHOTOS,
      'Successfully uploaded photos.',
      'Error uploading photos',
    );

  const [uploadVideosMutation, { loading: uploadVideosLoading }] =
    useSnackMutation(
      UPLOAD_VIDEOS,
      'Successfully uploaded videos.',
      'Error uploading videos',
    );

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = data => {
    const floorplan = data.photos.find(photo => photo.isFloorPlan);
    if (floorplan && !labelId) {
      enqueueSnackbar('Please select a label for the floor plan.', {
        variant: 'error',
      });
      return;
    }
    const detailedPricing = data.detailedPricing.map(
      ({ expiry, noExpiry, paymentType, showOnWebsite, ...rest }) => ({
        ...rest,
        expiry: noExpiry ? null : moment(expiry).format('YYYY-MM-DD'),
        allowCash:
          paymentType === PAYMENT_TYPES.BOTH ||
          paymentType === PAYMENT_TYPES.CASH,
        allowFinance:
          paymentType === PAYMENT_TYPES.BOTH ||
          paymentType === PAYMENT_TYPES.FINANCE,
        showOnWebsite: showOnWebsite === 'true' || showOnWebsite === true,
      }),
    );
    Promise.all(
      detailedPricing.map(({ id, __typename, ...rest }) =>
        id
          ? updateDetailedPricing({
              variables: {
                id,
                data: { ...rest },
              },
            })
          : createDetailedPricing({
              variables: {
                data: {
                  vehicleId: vehicle.id,
                  ...rest,
                },
              },
            }),
      ),
      uploadPhotosMutation({
        variables: {
          id: vehicle.id,
          data: data.photos.map(
            ({ chosen, selected, __typename, key, isFloorPlan, ...rest }) => ({
              ...rest,
              labelId: isFloorPlan ? labelId : null,
              isFloorPlan,
              vehicleId: vehicle.id,
            }),
          ),
        },
      }),
      uploadVideosMutation({
        variables: {
          id: vehicle.id,
          data: data.videos.map(
            ({ chosen, selected, __typename, key, ...rest }) => ({
              ...rest,
              vehicleId: vehicle.id,
            }),
          ),
        },
      }),
    ).then(() => {
      const {
        detailedPricing,
        npvMakeId,
        npvManufacturerId,
        npvTypeId,
        photos,
        selectedYear,
        tag1Id,
        tag2Id,
        videos,
        ...rest
      } = data;
      const variables = {
        id: vehicle.id,
        data: {
          tag1Id: tag1Id === 0 ? null : tag1Id,
          tag2Id: tag2Id === 0 ? null : tag2Id,
          ...rest,
        },
      };
      updateNpvVehicle({ variables });
    });
  };

  // Whenever the photoList is changed, replace the form array
  useEffect(() => {
    photosFieldArray.replace(photoList);
    // eslint-disable-next-line
  }, [photoList]);

  // Whenever the videoList is changed, replace the form array
  useEffect(() => {
    videosFieldArray.replace(videoList);
    // eslint-disable-next-line
  }, [videoList]);

  if (
    loading ||
    createDetailedPricingLoading ||
    updateDetailedPricingLoading ||
    uploadPhotosLoading ||
    uploadVideosLoading
  ) {
    return <Loading />;
  }

  return (
    <FormProvider {...methods}>
      <Box margin={1} pb="75px">
        <LoadingBackdrop open={updateLoading}>Updating unit...</LoadingBackdrop>
        <Box>
          <DealerPicker disabled />
        </Box>
        <Box>
          <UnitDetails vehicle={vehicle} />
        </Box>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box mb={10}>
            <TabContext value={tabIndex}>
              <TabList
                onChange={(event, tabIndex) => setTabIndex(tabIndex)}
                variant={mobile ? 'scrollable' : 'fullWidth'}
                indicatorColor="secondary"
              >
                {tabList.map(tab => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </TabList>
              <TabPanel value="0">
                <EditUnitInfo vehicle={vehicle} formMethods={methods} />
              </TabPanel>
              <TabPanel value="1">
                <EditPricing vehicle={vehicle} />
              </TabPanel>
              <TabPanel value="2">
                <EditPhotosMedia
                  vehicle={vehicle}
                  photoList={photoList}
                  setPhotoList={setPhotoList}
                  videoList={videoList}
                  setVideoList={setVideoList}
                  labelId={labelId}
                  setLabelId={setLabelId}
                />
              </TabPanel>
              <TabPanel value="3">
                <EditSpecsFeatures vehicle={vehicle} />
              </TabPanel>
              <TabPanel value="4">
                <UnitHistory vehicle={vehicle} />
              </TabPanel>
            </TabContext>
          </Box>
          {formState.isDirty && <SaveChanges />}
        </form>
      </Box>
    </FormProvider>
  );
};

export default EditUnit;
