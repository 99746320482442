import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';

import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ConfirmDialog from 'components/MaterialUI/ConfirmDialog';
import { useUserContext } from 'components/MaterialUI/UserContext';
import gql from 'graphql-tag';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import AttachmentDownloadDialog from './AttachmentDownloadDialog';

const GET_ATTACHMENTS_FOR_OPP = gql`
  query getAttachments($_id: ID!) {
    opportunity(_id: $_id) {
      attachments {
        _id
        attachment_type
        key
        label
        created_by
        created_by_name
        date_created
        deleted
        file_tag
      }
    }
  }
`;

const GET_ATTACHMENT_URL = gql`
  query getUrl($key: String!, $_id: ID!, $code: String!) {
    getAttachmentUrl(key: $key, _id: $_id, code: $code) {
      url
    }
  }
`;

const ADD_ATTACHMENT_MUTATION = gql`
  mutation addAttachment($_id: ID!, $input: AttachmentUpload!) {
    uploadAttachment(_id: $_id, input: $input) {
      attachments {
        _id
        attachment_type
        key
        label
        created_by
        created_by_name
        date_created
        deleted
        file_tag
      }
    }
  }
`;

const REMOVE_ATTACHMENT_MUTATION = gql`
  mutation removeAttachment($_id: ID!, $attachmentID: ID!) {
    removeAttachment(_id: $_id, attachmentID: $attachmentID) {
      attachments {
        _id
        attachment_type
        key
        label
        created_by
        created_by_name
        date_created
        deleted
        file_tag
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
  dialogTitle: {
    padding: '16px 38px',
  },
  saveButton: {
    marginTop: '13px',
    marginLeft: '1rem',
    backgroundColor: '#74B72E',
  },
  closeButton: {
    fontWeight: '600',
    fontSize: 16,
    color: '#24a0ed',
  },
}));

function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

const DealLogAttachments = ({
  handleClose,
  opportunityID,
  dealNumber,
  isServiceRole,
}) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { currentUser } = useUserContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [label, setLabel] = useState('');
  const [type, setType] = useState('Other');
  const [uploadData, setUploadData] = useState({});
  const [downloadAttachmentKey, setDownloadAttachmentKey] = useState();

  const { data, loading, refetch } = useQuery(GET_ATTACHMENTS_FOR_OPP, {
    variables: { _id: opportunityID },
  });
  const [getAttachmentUrl, attachmentUrlQuery] = useLazyQuery(
    GET_ATTACHMENT_URL,
    {
      onError: ({ graphQLErrors }) =>
        graphQLErrors.forEach(gqlError =>
          enqueueSnackbar(
            gqlError.extensions?.response?.body?.message ?? gqlError.message,
            { variant: 'error' },
          ),
        ),
      onCompleted: () => setDownloadAttachmentKey(null),
    },
  );
  const [addAttachment] = useMutation(ADD_ATTACHMENT_MUTATION);
  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT_MUTATION, {
    onCompleted: () => {
      refetch();
      enqueueSnackbar('Attachment removed');
    },
    onError: e => {
      enqueueSnackbar(e.message, { variant: 'error' });
    },
  });
  const [deleteAttachedDocument, setDeleteAttachedDocument] = useState(null);

  useEffect(() => {
    if (attachmentUrlQuery.data) {
      const url = attachmentUrlQuery.data.getAttachmentUrl.url;
      window.open(url);
    }
  }, [attachmentUrlQuery]);

  const handleViewItemClick = attachmentKey =>
    setDownloadAttachmentKey(attachmentKey);

  const handleCodeSubmit = ({ attachmentKey, code }) =>
    getAttachmentUrl({
      variables: {
        key: attachmentKey,
        _id: opportunityID,
        code,
      },
      fetchPolicy: 'network-only',
    });

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const handleDeleteClick = attachmentID => () => {
    removeAttachment({
      variables: {
        _id: opportunityID,
        attachmentID: attachmentID,
      },
    });
  };

  const handleUpload = event => {
    addAttachment({
      variables: {
        _id: opportunityID,
        input: {
          filename: uploadData.label,
          data: uploadData.dataURI,
          extension: uploadData.extension,
          attachment_type: 'deallog',
          file_tag: type,
        },
      },
    })
      .catch(e => {
        console.log(e);
      })
      .then(r => {
        refetch();
        return enqueueSnackbar('Attachment added!');
      });
  };

  async function handleLabelChange(event) {
    const files = event.target.files;
    const extension = files[0].name.split('.').slice(-1)[0];
    let base64file = await getBase64(files[0]);
    const data = {
      label: files[0].name,
      dataURI: base64file,
      extension: extension,
    };
    setUploadData(data);
    setLabel(files[0].name);
  }

  const attachments = data?.opportunity?.attachments || [];

  return (
    <>
      <AttachmentDownloadDialog
        attachmentKey={downloadAttachmentKey}
        id={opportunityID}
        onClose={() => setDownloadAttachmentKey(null)}
        onSubmit={handleCodeSubmit}
      />
      <DialogTitle className={classes.dialogTitle}>
        <h2>
          Attachments for Deal #{dealNumber} - {currentUser.display_name}
        </h2>
      </DialogTitle>
      <DialogContent>
        <form style={{ paddingTop: '1rem' }}>
          <TextField
            variant="outlined"
            size="small"
            style={{
              maxWidth: '350px',
              width: '100%',
            }}
            disabled
            value={label}
          />
          <span
            style={{
              paddingLeft: '1rem',
            }}
          >
            <input
              className={classes.input}
              id="attachment-file"
              type="file"
              onChange={handleLabelChange}
            />
          </span>
          <label htmlFor="attachment-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{
                marginLeft: `${isDesktop ? '1rem' : 0}`,
                marginTop: `${isDesktop ? 0 : '1rem'}`,
              }}
            >
              <FolderIcon fontSize="small" style={{ marginRight: '5px' }} />
              BROWSE
            </Button>
          </label>
          <div style={{ padding: '1rem 0 1rem 0' }}>
            <FormControl>
              <InputLabel id="attachment-type">Type</InputLabel>
              <Select
                labelId="attachment-type"
                value={type}
                style={{ width: '200px' }}
                onChange={handleTypeChange}
                label="Type"
              >
                <MenuItem value="AMVIC Inspection">AMVIC Inspection</MenuItem>
                <MenuItem value="Deal Jacket">Deal Jacket</MenuItem>
                <MenuItem value="Delivery Checklist">
                  Delivery Checklist
                </MenuItem>
                <MenuItem value="Funding Notice">Funding Notice</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.saveButton}
              onClick={handleUpload}
              disabled={label === ''}
              startIcon={<CheckIcon />}
              style={{
                marginLeft: `${isDesktop ? '1rem' : 0}`,
                marginTop: `${isDesktop ? 0 : '1rem'}`,
              }}
            >
              Save
            </Button>
          </div>
        </form>
        <h4>Uploaded Attachments</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments &&
              attachments.map(
                item =>
                  !item.deleted && (
                    <TableRow key={item._id}>
                      <TableCell>
                        {item.label}
                        <div style={{ whiteSpace: 'pre-line', color: 'gray' }}>
                          {item.file_tag || 'Other'}
                        </div>
                      </TableCell>
                      <TableCell>
                        {moment(item.date_created).format('ll')}
                      </TableCell>
                      <TableCell>{item.created_by_name}</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <IconButton
                            size="small"
                            onClick={() => handleViewItemClick(item.key)}
                          >
                            <InsertDriveFileIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            disabled={isServiceRole}
                            onClick={() => setDeleteAttachedDocument(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ),
              )}
          </TableBody>
        </Table>
      </DialogContent>
      <ConfirmDialog
        abortText="Cancel"
        confirmText="Delete"
        isOpen={Boolean(deleteAttachedDocument)}
        onClose={() => setDeleteAttachedDocument(null)}
        onConfirm={handleDeleteClick(deleteAttachedDocument?._id)}
        text={`Are you sure you want to delete ${deleteAttachedDocument?.label} attachment?`}
      />
      <DialogActions>
        <Button className={classes.closeButton} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
      {loading && (
        <>
          <p>Loading attachments...</p>
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

export default DealLogAttachments;
